import { default as emailsgRr3DLt7mWMeta } from "/build/0dc57548-204e-4d08-b295-f1c2492aecd4/pages/admin/emails.vue?macro=true";
import { default as integrationsPxZ8WJiDNXMeta } from "/build/0dc57548-204e-4d08-b295-f1c2492aecd4/pages/admin/integrations.vue?macro=true";
import { default as overviewHQDAupZqv0Meta } from "/build/0dc57548-204e-4d08-b295-f1c2492aecd4/pages/admin/overview.vue?macro=true";
import { default as indexaL4QbCSAeQMeta } from "/build/0dc57548-204e-4d08-b295-f1c2492aecd4/pages/admin/structures/[id]/index.vue?macro=true";
import { default as subjectsTNZNK2HmmIMeta } from "/build/0dc57548-204e-4d08-b295-f1c2492aecd4/pages/admin/structures/[id]/subjects.vue?macro=true";
import { default as indexvbnwkdpsYGMeta } from "/build/0dc57548-204e-4d08-b295-f1c2492aecd4/pages/admin/structures/index.vue?macro=true";
import { default as indexuYSZ5cuwNjMeta } from "/build/0dc57548-204e-4d08-b295-f1c2492aecd4/pages/admin/users/[id]/index.vue?macro=true";
import { default as addsVBE16scYLMeta } from "/build/0dc57548-204e-4d08-b295-f1c2492aecd4/pages/admin/users/add.vue?macro=true";
import { default as indexv33F7seQZSMeta } from "/build/0dc57548-204e-4d08-b295-f1c2492aecd4/pages/admin/users/index.vue?macro=true";
import { default as admin1Mp1a1CvkcMeta } from "/build/0dc57548-204e-4d08-b295-f1c2492aecd4/pages/admin.vue?macro=true";
import { default as indexdM2zFkZsAlMeta } from "/build/0dc57548-204e-4d08-b295-f1c2492aecd4/pages/app/beneficiaries/[id]/archive/index.vue?macro=true";
import { default as entourageooWgCnrOUGMeta } from "/build/0dc57548-204e-4d08-b295-f1c2492aecd4/pages/app/beneficiaries/[id]/edit/entourage.vue?macro=true";
import { default as external_45organisationsEpR8OraetoMeta } from "/build/0dc57548-204e-4d08-b295-f1c2492aecd4/pages/app/beneficiaries/[id]/edit/external-organisations.vue?macro=true";
import { default as healthtA71duzUpFMeta } from "/build/0dc57548-204e-4d08-b295-f1c2492aecd4/pages/app/beneficiaries/[id]/edit/health.vue?macro=true";
import { default as info1UVH3XUoIXMeta } from "/build/0dc57548-204e-4d08-b295-f1c2492aecd4/pages/app/beneficiaries/[id]/edit/info.vue?macro=true";
import { default as occupation1YH7tghEJxMeta } from "/build/0dc57548-204e-4d08-b295-f1c2492aecd4/pages/app/beneficiaries/[id]/edit/occupation.vue?macro=true";
import { default as tax_45householdGlbsvLURCAMeta } from "/build/0dc57548-204e-4d08-b295-f1c2492aecd4/pages/app/beneficiaries/[id]/edit/tax-household.vue?macro=true";
import { default as printOomLjCzeyjMeta } from "/build/0dc57548-204e-4d08-b295-f1c2492aecd4/pages/app/beneficiaries/[id]/print.vue?macro=true";
import { default as indexoetafpSgsiMeta } from "/build/0dc57548-204e-4d08-b295-f1c2492aecd4/pages/app/beneficiaries/index.vue?macro=true";
import { default as edit2LQ1ssXtKdMeta } from "/build/0dc57548-204e-4d08-b295-f1c2492aecd4/pages/app/files/[id]/budget/edit.vue?macro=true";
import { default as editwVntM5PFCfMeta } from "/build/0dc57548-204e-4d08-b295-f1c2492aecd4/pages/app/files/[id]/expenses/[eid]/edit.vue?macro=true";
import { default as editwgOSZK3NWoMeta } from "/build/0dc57548-204e-4d08-b295-f1c2492aecd4/pages/app/files/[id]/followups/[fid]/edit.vue?macro=true";
import { default as addcBTzYThQtOMeta } from "/build/0dc57548-204e-4d08-b295-f1c2492aecd4/pages/app/files/[id]/followups/add.vue?macro=true";
import { default as edith2qUBOAfk9Meta } from "/build/0dc57548-204e-4d08-b295-f1c2492aecd4/pages/app/files/[id]/help-requests/[subject]/[hrid]/edit.vue?macro=true";
import { default as addcPleHaSo12Meta } from "/build/0dc57548-204e-4d08-b295-f1c2492aecd4/pages/app/files/[id]/help-requests/[subject]/add.vue?macro=true";
import { default as editYdSl5N9qICMeta } from "/build/0dc57548-204e-4d08-b295-f1c2492aecd4/pages/app/files/[id]/help-requests/housing/[hrid]/edit.vue?macro=true";
import { default as addwMII4sH1hUMeta } from "/build/0dc57548-204e-4d08-b295-f1c2492aecd4/pages/app/files/[id]/help-requests/housing/add.vue?macro=true";
import { default as editdRIm1uzUeWMeta } from "/build/0dc57548-204e-4d08-b295-f1c2492aecd4/pages/app/files/[id]/income/[iid]/edit.vue?macro=true";
import { default as indexwu7kBhyWAfMeta } from "/build/0dc57548-204e-4d08-b295-f1c2492aecd4/pages/app/files/[id]/index.vue?macro=true";
import { default as addeg6BOyL9IRMeta } from "/build/0dc57548-204e-4d08-b295-f1c2492aecd4/pages/app/files/add.vue?macro=true";
import { default as historyKt6GuCjHHMMeta } from "/build/0dc57548-204e-4d08-b295-f1c2492aecd4/pages/app/history.vue?macro=true";
import { default as printxeTZkay7jWMeta } from "/build/0dc57548-204e-4d08-b295-f1c2492aecd4/pages/app/historyprint/[id]/print.vue?macro=true";
import { default as index_46clientzPGgx0PmAfMeta } from "/build/0dc57548-204e-4d08-b295-f1c2492aecd4/pages/app/integrations/rdvsp/index.client.vue?macro=true";

let _createClientPage
async function createClientPage(loader) {
  _createClientPage ||= await import("/build/0dc57548-204e-4d08-b295-f1c2492aecd4/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.13.9_eslint@9.21.0_jiti@2.4.2__ioredis@5._mtrmjxev3ygfue5d3wtpjij4yi/node_modules/nuxt/dist/components/runtime/client-component.js").then(r => r.createClientPage)
  return _createClientPage(loader);
}
import { default as overviewU2sct6TjysMeta } from "/build/0dc57548-204e-4d08-b295-f1c2492aecd4/pages/app/overview.vue?macro=true";
import { default as partnersxesczNFRSqMeta } from "/build/0dc57548-204e-4d08-b295-f1c2492aecd4/pages/app/partners.vue?macro=true";
import { default as statsbZYLXlxs9IMeta } from "/build/0dc57548-204e-4d08-b295-f1c2492aecd4/pages/app/stats.vue?macro=true";
import { default as indexY4vexIdCEuMeta } from "/build/0dc57548-204e-4d08-b295-f1c2492aecd4/pages/app/structure/index.vue?macro=true";
import { default as subjectsHKzIbSjtw8Meta } from "/build/0dc57548-204e-4d08-b295-f1c2492aecd4/pages/app/structure/subjects.vue?macro=true";
import { default as usersy2Qt4jJDgzMeta } from "/build/0dc57548-204e-4d08-b295-f1c2492aecd4/pages/app/users.vue?macro=true";
import { default as apppSghampIfoMeta } from "/build/0dc57548-204e-4d08-b295-f1c2492aecd4/pages/app.vue?macro=true";
import { default as admin8HpSvn7T57Meta } from "/build/0dc57548-204e-4d08-b295-f1c2492aecd4/pages/auth/admin.vue?macro=true";
import { default as loginPC1a6S3ZHhMeta } from "/build/0dc57548-204e-4d08-b295-f1c2492aecd4/pages/auth/login.vue?macro=true";
import { default as logoutfovUcNukjOMeta } from "/build/0dc57548-204e-4d08-b295-f1c2492aecd4/pages/auth/logout.vue?macro=true";
import { default as validate_45tokenDYOoj61vhoMeta } from "/build/0dc57548-204e-4d08-b295-f1c2492aecd4/pages/auth/validate-token.vue?macro=true";
import { default as manageryYku3dtlqhMeta } from "/build/0dc57548-204e-4d08-b295-f1c2492aecd4/pages/cgu/manager.vue?macro=true";
import { default as user7g5WHm4hFUMeta } from "/build/0dc57548-204e-4d08-b295-f1c2492aecd4/pages/cgu/user.vue?macro=true";
import { default as cgupHy2BsIwy7Meta } from "/build/0dc57548-204e-4d08-b295-f1c2492aecd4/pages/cgu.vue?macro=true";
import { default as declaration_45accessibilitep9DvL25bHyMeta } from "/build/0dc57548-204e-4d08-b295-f1c2492aecd4/pages/declaration-accessibilite.vue?macro=true";
import { default as disabled_45accountnPFOJrlyR2Meta } from "/build/0dc57548-204e-4d08-b295-f1c2492aecd4/pages/error/disabled-account.vue?macro=true";
import { default as invalid_45tokenAJcUV6H7k6Meta } from "/build/0dc57548-204e-4d08-b295-f1c2492aecd4/pages/error/invalid-token.vue?macro=true";
import { default as unknown_45userqukYTw5pTIMeta } from "/build/0dc57548-204e-4d08-b295-f1c2492aecd4/pages/error/unknown-user.vue?macro=true";
import { default as index7p7xN7LKdUMeta } from "/build/0dc57548-204e-4d08-b295-f1c2492aecd4/pages/index.vue?macro=true";
import { default as mentions_45legalesbkqliFFdrhMeta } from "/build/0dc57548-204e-4d08-b295-f1c2492aecd4/pages/mentions-legales.vue?macro=true";
import { default as politique_45confidentialiteDmtDLXnTHWMeta } from "/build/0dc57548-204e-4d08-b295-f1c2492aecd4/pages/politique-confidentialite.vue?macro=true";
import { default as register5X2oWLK9QKMeta } from "/build/0dc57548-204e-4d08-b295-f1c2492aecd4/pages/register.vue?macro=true";
import { default as statistiques6XipaV9obfMeta } from "/build/0dc57548-204e-4d08-b295-f1c2492aecd4/pages/statistiques.vue?macro=true";
import { default as storiesr4Tw5AgxmJMeta } from "/build/0dc57548-204e-4d08-b295-f1c2492aecd4/pages/stories.vue?macro=true";
export default [
  {
    name: "admin",
    path: "/admin",
    meta: admin1Mp1a1CvkcMeta || {},
    component: () => import("/build/0dc57548-204e-4d08-b295-f1c2492aecd4/pages/admin.vue"),
    children: [
  {
    name: "admin-emails",
    path: "emails",
    component: () => import("/build/0dc57548-204e-4d08-b295-f1c2492aecd4/pages/admin/emails.vue")
  },
  {
    name: "admin-integrations",
    path: "integrations",
    component: () => import("/build/0dc57548-204e-4d08-b295-f1c2492aecd4/pages/admin/integrations.vue")
  },
  {
    name: overviewHQDAupZqv0Meta?.name ?? "admin-overview",
    path: "overview",
    meta: overviewHQDAupZqv0Meta || {},
    component: () => import("/build/0dc57548-204e-4d08-b295-f1c2492aecd4/pages/admin/overview.vue")
  },
  {
    name: "admin-structures-id",
    path: "structures/:id()",
    component: () => import("/build/0dc57548-204e-4d08-b295-f1c2492aecd4/pages/admin/structures/[id]/index.vue")
  },
  {
    name: "admin-structures-id-subjects",
    path: "structures/:id()/subjects",
    component: () => import("/build/0dc57548-204e-4d08-b295-f1c2492aecd4/pages/admin/structures/[id]/subjects.vue")
  },
  {
    name: "admin-structures",
    path: "structures",
    component: () => import("/build/0dc57548-204e-4d08-b295-f1c2492aecd4/pages/admin/structures/index.vue")
  },
  {
    name: "admin-users-id",
    path: "users/:id()",
    component: () => import("/build/0dc57548-204e-4d08-b295-f1c2492aecd4/pages/admin/users/[id]/index.vue")
  },
  {
    name: "admin-users-add",
    path: "users/add",
    component: () => import("/build/0dc57548-204e-4d08-b295-f1c2492aecd4/pages/admin/users/add.vue")
  },
  {
    name: "admin-users",
    path: "users",
    component: () => import("/build/0dc57548-204e-4d08-b295-f1c2492aecd4/pages/admin/users/index.vue")
  }
]
  },
  {
    name: "app",
    path: "/app",
    meta: apppSghampIfoMeta || {},
    component: () => import("/build/0dc57548-204e-4d08-b295-f1c2492aecd4/pages/app.vue"),
    children: [
  {
    name: indexdM2zFkZsAlMeta?.name ?? "app-beneficiaries-id-archive",
    path: "beneficiaries/:id()/archive",
    meta: indexdM2zFkZsAlMeta || {},
    component: () => import("/build/0dc57548-204e-4d08-b295-f1c2492aecd4/pages/app/beneficiaries/[id]/archive/index.vue")
  },
  {
    name: "app-beneficiaries-id-edit-entourage",
    path: "beneficiaries/:id()/edit/entourage",
    component: () => import("/build/0dc57548-204e-4d08-b295-f1c2492aecd4/pages/app/beneficiaries/[id]/edit/entourage.vue")
  },
  {
    name: "app-beneficiaries-id-edit-external-organisations",
    path: "beneficiaries/:id()/edit/external-organisations",
    component: () => import("/build/0dc57548-204e-4d08-b295-f1c2492aecd4/pages/app/beneficiaries/[id]/edit/external-organisations.vue")
  },
  {
    name: "app-beneficiaries-id-edit-health",
    path: "beneficiaries/:id()/edit/health",
    component: () => import("/build/0dc57548-204e-4d08-b295-f1c2492aecd4/pages/app/beneficiaries/[id]/edit/health.vue")
  },
  {
    name: "app-beneficiaries-id-edit-info",
    path: "beneficiaries/:id()/edit/info",
    component: () => import("/build/0dc57548-204e-4d08-b295-f1c2492aecd4/pages/app/beneficiaries/[id]/edit/info.vue")
  },
  {
    name: occupation1YH7tghEJxMeta?.name ?? "app-beneficiaries-id-edit-occupation",
    path: "beneficiaries/:id()/edit/occupation",
    meta: occupation1YH7tghEJxMeta || {},
    component: () => import("/build/0dc57548-204e-4d08-b295-f1c2492aecd4/pages/app/beneficiaries/[id]/edit/occupation.vue")
  },
  {
    name: "app-beneficiaries-id-edit-tax-household",
    path: "beneficiaries/:id()/edit/tax-household",
    component: () => import("/build/0dc57548-204e-4d08-b295-f1c2492aecd4/pages/app/beneficiaries/[id]/edit/tax-household.vue")
  },
  {
    name: "app-beneficiaries-id-print",
    path: "beneficiaries/:id()/print",
    meta: printOomLjCzeyjMeta || {},
    component: () => import("/build/0dc57548-204e-4d08-b295-f1c2492aecd4/pages/app/beneficiaries/[id]/print.vue")
  },
  {
    name: indexoetafpSgsiMeta?.name ?? "app-beneficiaries",
    path: "beneficiaries",
    meta: indexoetafpSgsiMeta || {},
    component: () => import("/build/0dc57548-204e-4d08-b295-f1c2492aecd4/pages/app/beneficiaries/index.vue")
  },
  {
    name: edit2LQ1ssXtKdMeta?.name ?? "app-files-id-budget-edit",
    path: "files/:id()/budget/edit",
    meta: edit2LQ1ssXtKdMeta || {},
    component: () => import("/build/0dc57548-204e-4d08-b295-f1c2492aecd4/pages/app/files/[id]/budget/edit.vue")
  },
  {
    name: editwVntM5PFCfMeta?.name ?? "app-files-id-expenses-eid-edit",
    path: "files/:id()/expenses/:eid()/edit",
    meta: editwVntM5PFCfMeta || {},
    component: () => import("/build/0dc57548-204e-4d08-b295-f1c2492aecd4/pages/app/files/[id]/expenses/[eid]/edit.vue")
  },
  {
    name: "app-files-id-followups-fid-edit",
    path: "files/:id()/followups/:fid()/edit",
    component: () => import("/build/0dc57548-204e-4d08-b295-f1c2492aecd4/pages/app/files/[id]/followups/[fid]/edit.vue")
  },
  {
    name: "app-files-id-followups-add",
    path: "files/:id()/followups/add",
    component: () => import("/build/0dc57548-204e-4d08-b295-f1c2492aecd4/pages/app/files/[id]/followups/add.vue")
  },
  {
    name: "app-files-id-help-requests-subject-hrid-edit",
    path: "files/:id()/help-requests/:subject()/:hrid()/edit",
    component: () => import("/build/0dc57548-204e-4d08-b295-f1c2492aecd4/pages/app/files/[id]/help-requests/[subject]/[hrid]/edit.vue")
  },
  {
    name: "app-files-id-help-requests-subject-add",
    path: "files/:id()/help-requests/:subject()/add",
    component: () => import("/build/0dc57548-204e-4d08-b295-f1c2492aecd4/pages/app/files/[id]/help-requests/[subject]/add.vue")
  },
  {
    name: "app-files-id-help-requests-housing-hrid-edit",
    path: "files/:id()/help-requests/housing/:hrid()/edit",
    component: () => import("/build/0dc57548-204e-4d08-b295-f1c2492aecd4/pages/app/files/[id]/help-requests/housing/[hrid]/edit.vue")
  },
  {
    name: "app-files-id-help-requests-housing-add",
    path: "files/:id()/help-requests/housing/add",
    component: () => import("/build/0dc57548-204e-4d08-b295-f1c2492aecd4/pages/app/files/[id]/help-requests/housing/add.vue")
  },
  {
    name: editdRIm1uzUeWMeta?.name ?? "app-files-id-income-iid-edit",
    path: "files/:id()/income/:iid()/edit",
    meta: editdRIm1uzUeWMeta || {},
    component: () => import("/build/0dc57548-204e-4d08-b295-f1c2492aecd4/pages/app/files/[id]/income/[iid]/edit.vue")
  },
  {
    name: "app-files-id",
    path: "files/:id()",
    component: () => import("/build/0dc57548-204e-4d08-b295-f1c2492aecd4/pages/app/files/[id]/index.vue")
  },
  {
    name: "app-files-add",
    path: "files/add",
    component: () => import("/build/0dc57548-204e-4d08-b295-f1c2492aecd4/pages/app/files/add.vue")
  },
  {
    name: "app-history",
    path: "history",
    component: () => import("/build/0dc57548-204e-4d08-b295-f1c2492aecd4/pages/app/history.vue")
  },
  {
    name: "app-historyprint-id-print",
    path: "historyprint/:id()/print",
    meta: printxeTZkay7jWMeta || {},
    component: () => import("/build/0dc57548-204e-4d08-b295-f1c2492aecd4/pages/app/historyprint/[id]/print.vue")
  },
  {
    name: "app-integrations-rdvsp",
    path: "integrations/rdvsp",
    meta: index_46clientzPGgx0PmAfMeta || {},
    component: () => createClientPage(() => import("/build/0dc57548-204e-4d08-b295-f1c2492aecd4/pages/app/integrations/rdvsp/index.client.vue"))
  },
  {
    name: overviewU2sct6TjysMeta?.name ?? "app-overview",
    path: "overview",
    meta: overviewU2sct6TjysMeta || {},
    component: () => import("/build/0dc57548-204e-4d08-b295-f1c2492aecd4/pages/app/overview.vue")
  },
  {
    name: "app-partners",
    path: "partners",
    component: () => import("/build/0dc57548-204e-4d08-b295-f1c2492aecd4/pages/app/partners.vue")
  },
  {
    name: "app-stats",
    path: "stats",
    component: () => import("/build/0dc57548-204e-4d08-b295-f1c2492aecd4/pages/app/stats.vue")
  },
  {
    name: "app-structure",
    path: "structure",
    component: () => import("/build/0dc57548-204e-4d08-b295-f1c2492aecd4/pages/app/structure/index.vue")
  },
  {
    name: "app-structure-subjects",
    path: "structure/subjects",
    component: () => import("/build/0dc57548-204e-4d08-b295-f1c2492aecd4/pages/app/structure/subjects.vue")
  },
  {
    name: "app-users",
    path: "users",
    component: () => import("/build/0dc57548-204e-4d08-b295-f1c2492aecd4/pages/app/users.vue")
  }
]
  },
  {
    name: "auth-admin",
    path: "/auth/admin",
    meta: admin8HpSvn7T57Meta || {},
    component: () => import("/build/0dc57548-204e-4d08-b295-f1c2492aecd4/pages/auth/admin.vue")
  },
  {
    name: "auth-login",
    path: "/auth/login",
    meta: loginPC1a6S3ZHhMeta || {},
    component: () => import("/build/0dc57548-204e-4d08-b295-f1c2492aecd4/pages/auth/login.vue")
  },
  {
    name: "auth-logout",
    path: "/auth/logout",
    meta: logoutfovUcNukjOMeta || {},
    component: () => import("/build/0dc57548-204e-4d08-b295-f1c2492aecd4/pages/auth/logout.vue")
  },
  {
    name: "auth-validate-token",
    path: "/auth/validate-token",
    meta: validate_45tokenDYOoj61vhoMeta || {},
    component: () => import("/build/0dc57548-204e-4d08-b295-f1c2492aecd4/pages/auth/validate-token.vue")
  },
  {
    name: "cgu",
    path: "/cgu",
    meta: cgupHy2BsIwy7Meta || {},
    component: () => import("/build/0dc57548-204e-4d08-b295-f1c2492aecd4/pages/cgu.vue"),
    children: [
  {
    name: "cgu-manager",
    path: "manager",
    component: () => import("/build/0dc57548-204e-4d08-b295-f1c2492aecd4/pages/cgu/manager.vue")
  },
  {
    name: "cgu-user",
    path: "user",
    component: () => import("/build/0dc57548-204e-4d08-b295-f1c2492aecd4/pages/cgu/user.vue")
  }
]
  },
  {
    name: "declaration-accessibilite",
    path: "/declaration-accessibilite",
    meta: declaration_45accessibilitep9DvL25bHyMeta || {},
    component: () => import("/build/0dc57548-204e-4d08-b295-f1c2492aecd4/pages/declaration-accessibilite.vue")
  },
  {
    name: "error-disabled-account",
    path: "/error/disabled-account",
    meta: disabled_45accountnPFOJrlyR2Meta || {},
    component: () => import("/build/0dc57548-204e-4d08-b295-f1c2492aecd4/pages/error/disabled-account.vue")
  },
  {
    name: "error-invalid-token",
    path: "/error/invalid-token",
    meta: invalid_45tokenAJcUV6H7k6Meta || {},
    component: () => import("/build/0dc57548-204e-4d08-b295-f1c2492aecd4/pages/error/invalid-token.vue")
  },
  {
    name: "error-unknown-user",
    path: "/error/unknown-user",
    meta: unknown_45userqukYTw5pTIMeta || {},
    component: () => import("/build/0dc57548-204e-4d08-b295-f1c2492aecd4/pages/error/unknown-user.vue")
  },
  {
    name: "index",
    path: "/",
    meta: index7p7xN7LKdUMeta || {},
    component: () => import("/build/0dc57548-204e-4d08-b295-f1c2492aecd4/pages/index.vue")
  },
  {
    name: "mentions-legales",
    path: "/mentions-legales",
    meta: mentions_45legalesbkqliFFdrhMeta || {},
    component: () => import("/build/0dc57548-204e-4d08-b295-f1c2492aecd4/pages/mentions-legales.vue")
  },
  {
    name: "politique-confidentialite",
    path: "/politique-confidentialite",
    meta: politique_45confidentialiteDmtDLXnTHWMeta || {},
    component: () => import("/build/0dc57548-204e-4d08-b295-f1c2492aecd4/pages/politique-confidentialite.vue")
  },
  {
    name: "register",
    path: "/register",
    meta: register5X2oWLK9QKMeta || {},
    component: () => import("/build/0dc57548-204e-4d08-b295-f1c2492aecd4/pages/register.vue")
  },
  {
    name: "statistiques",
    path: "/statistiques",
    meta: statistiques6XipaV9obfMeta || {},
    component: () => import("/build/0dc57548-204e-4d08-b295-f1c2492aecd4/pages/statistiques.vue")
  },
  {
    name: "stories",
    path: "/stories",
    meta: storiesr4Tw5AgxmJMeta || {},
    component: () => import("/build/0dc57548-204e-4d08-b295-f1c2492aecd4/pages/stories.vue")
  }
]